a {
  text-decoration: none;
  color: black;
}
.Amber {
  padding: 0 5vw;
}
/* Партнерская программа */

.Amber h2 {
  text-align: center;
  font-size: 12vw;
  font-weight: 900;

  background: linear-gradient(90deg, #001f2b 0%, #0045a1 43%, #0095e9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.grad {
  background: linear-gradient(
    87.26deg,
    #0370f3 1.04%,
    #30aefc 85.21%,
    #9ddcff 101.04%
  );
  box-shadow: 0px 8px 25px rgba(0, 124, 238, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2vw;
  padding: 4vw;
}
.grad p {
  color: #fff;
  font-size: 6vw;
  text-align: center;
  margin-bottom: 4vw;
  font-weight: 600;
}
.grad span {
  background: #ffffff;
  text-align: center;
  padding: 4vw;
  font-size: 4vw;
  border-radius: 2vw;
  font-weight: 600;
}
.Amber h3 {
  text-transform: uppercase;
  color: #174755;
  text-align: center;
  margin: 4vw 0;
  margin-bottom: 6vw;
  font-size: 7vw;
}
.reg {
  display: block;
}
.Amber h4 {
  text-align: center;
  font-size: 8vw;
  margin: 6vw 0;
  background: linear-gradient(90deg, #001f2b 0%, #0045a1 43%, #0095e9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 900;
  margin-top: 8vw;
  color: rgba(0, 69, 161, 1);
}
.Amber .phone {
  width: 90%;
  min-height: 600px;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.man {
  width: 90%;
}
.Amber h5 {
  font-weight: 900;
  font-size: 10vw;
  margin: 4vw 0;
}
.Amber h5 span {
  display: block;
  color: #0370f3;
  font-size: 7vw;
}
.txt {
  font-size: 4vw;
}
/* Frame 408496754 */

.blueGrad {
  background: linear-gradient(87.26deg, #0c3362 1.04%, #00a3ff 101.04%);
  box-shadow: 0px 8px 25px rgba(0, 124, 238, 0.4);
  padding: 4vw;
  border-radius: 4vw;
  color: #fff;
  font-size: 4vw;
  line-height: 1.5em;
  margin: 5vw 0;
}
.blueGrad span {
  color: #1bf1ff;
  text-transform: uppercase;
  font-weight: 700;
}
ul {
  list-style-type: none;
}
.man {
  width: 100%;
}
.theory {
  font-size: 8vw;
  margin: 5vw 0;
  font-weight: 900;
}
.osn {
  font-weight: 600;
  font-size: 4vw;
}
.osn b {
  color: #0370f3;
}
.block {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5vw;
  justify-content: space-between;
}
.block div {
  width: 48%;
  box-shadow: 0px 8px 25px rgba(0, 124, 238, 0.4);
  border-radius: 4vw;
  margin-bottom: 4vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 4vw;
  font-size: 4vw;
  font-weight: bold;
}
ul li div {
  background: #6bd075;
  color: #fff;
  font-weight: 900;
  width: 10vw;
  min-width: 10vw;
  height: 10vw;
  display: flex;
  font-size: 6vw;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.Amber ul li {
  display: flex;
  align-items: center;
  margin-bottom: 5vw;
}
.Amber ul {
  margin: 5vw 0;
}
.Amber ul li p {
  margin-left: 4vw;
  font-weight: 600;
  font-size: 4vw;
}
.gr {
  background: linear-gradient(89.22deg, #29ae13 0.54%, #60da00 99.48%);
  box-shadow: 0px 8px 25px rgba(62, 238, 0, 0.4);
  text-transform: uppercase;
  padding: 3vw;
  border-radius: 2vw;
  font-size: 5vw;
  font-weight: bold;
  width: fit-content;
  margin-top: 3vw;
} /* Frame 408496776 */

.grS {
  background: linear-gradient(89.22deg, #29ae13 0.54%, #60da00 99.48%);
  box-shadow: 0px 8px 25px rgba(62, 238, 0, 0.4);
  text-transform: uppercase;
  padding: 3vw;
  border-radius: 2vw;
  font-size: 4vw;

  color: #fff;
  font-weight: bold;
  width: fit-content;
  margin-top: 6vw;
}
.swiper img {
  width: 99.5%;
}
.Amber h5 b {
  color: #0370f3;
  display: block;
  font-weight: 900 !important;
}
.five {
  font-weight: 800;
  font-size: 7vw;
}
.pizdec {
  margin: 3vw 0;
  font-size: 4vw;
  font-weight: 600;
}
.pizdec span {
  font-weight: 700;
}
.hand {
  display: flex;
  align-items: center;
  font-size: 5vw;
  font-weight: bold;
  margin: 4vw auto;
  width: fit-content;
}
.hand img {
  margin-left: 2vw;
}
.reg {
  background: linear-gradient(89.22deg, #29ae13 0.54%, #60da00 99.48%);
  box-shadow: 0px 8px 16px rgba(10, 167, 63, 0.3);
  padding: 4vw 7vw;
  font-size: 5vw;
  text-transform: uppercase;
  width: fit-content;
  border-radius: 10vw;
  color: #fff;
  font-weight: 600;
} /* Frame 408496783 */
.die li {
  align-items: flex-start;
}
.int {
  margin: 4vw 0;
  width: 50vw;
  margin-top: 10vw;
}
.ss img {
  border-radius: 4vw;
  border: 1px solid #0370f3;
}
.ss {
  margin: 8vw 0;
}
.olo {
  font-size: 8vw;
  text-transform: uppercase;
  text-align: center;
  margin-top: 8vw;
  font-weight: bold;
}
.olo b {
  display: block;
  font-size: 11vw;
  color: #00a3ff;
}
.but div,
.but a {
  width: 100% !important;
  text-align: center;
  border-radius: 10vw;
  padding: 6vw 7vw;
  font-size: 5vw;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  margin-top: 6vw;
}
.but .lend {
  background: linear-gradient(89.22deg, #006ae6 14.97%, #0094ff 99.48%);
  box-shadow: 0px 11px 30px rgba(0, 133, 255, 0.3);
} /* Frame 408496783 */
.Amber footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12vw;
  padding-top: 6vw;
  border-top: 1px solid #006ae6;
  padding-bottom: 8vw;
}
.Amber footer span {
  color: #3e95ff;
  margin-bottom: 1vw;
  display: block;
  font-weight: bold;
}
.ara {
  font-weight: bold;
  font-size: 7vw;
  margin: 4vw 0;
}
.Amber footer a {
  display: flex;
  align-items: center;

  padding: 2vw;
  margin: 1vw;
  margin-bottom: 6vw;
  border-radius: 2vw;
  box-shadow: 0px 11px 30px rgba(0, 133, 255, 0.3);
}
.Amber footer a img {
  margin-left: 2vw;
}
.Amber footer div {
  display: flex;
  justify-content: space-between;
}
.Amber header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4vw 0;
  margin-bottom: 12vw;
  border-bottom: 1px solid gainsboro;
}
.Amber header p {
  font-weight: bold;
}
.Amber header a img {
  width: 10vw;
  margin-left: 4vw;
}
.Amber header .lg {
  margin-right: auto;
}
.ss img {
  width: 99.7%;
  background: #fff;
}
.ss .swiper-slide {
  border-radius: 4vw;
}
.lend {
  display: block;
}
.but a {
  overflow: hidden;
  position: relative;
}
.keksAm {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.keksAm img {
  width: 10vw;
  margin-top: 2vw;
}
.keksAm p {
  font-size: 2vw;
  font-weight: bold;
  width: 40%;
  text-align: center;
  margin-bottom: 1vw;
}
.keksAm span {
  font-size: 1vw;
}
.but a .flare {
  position: absolute;
  top: 0;
  height: 100%;
  width: 45px;
  transform: skewX(-45deg);
  animation: flareAnimation;
  left: -150%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.4)
  );
  animation: flareAnimation 3s infinite linear;
}
