.thanks {
  width: 100vw;
  height: 100vh;
  background: url(./img/fon.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position-x: center;
}
.thanks h3 {
  margin-top: 28vh;
  font-size: 2vw;
  color: #31363d;
  text-align: center;
  margin-bottom: 2vw;
}
.thanks > p {
  color: #31363d;
  width: 40vw;
  font-size: 1.2vw;
  text-align: center;
  margin-bottom: 3vw;
}
.plank {
  /* Frame 408496111 */

  box-sizing: border-box;

  background: linear-gradient(
    90.23deg,
    #133661 0.17%,
    rgba(27, 106, 165, 0.73) 99.8%
  );
  border: 2px solid #8aeaff;
  box-shadow: 0px 4px 20px rgba(83, 131, 203, 0.25);
  border-radius: 20px;
  padding: 1.5vw;
  font-size: 1.5vw;
  color: #fff;
  width: 50vw;
}
.plank span {
  font-weight: bold;
  color: #7bf7ff;
}
.thanks .showBusiness {
  padding: 1.5vw;
  width: 20vw;
  text-align: center;
  font-size: 1vw;
  margin-top: 2vw;
}
@media (max-width: 640px) {
  .thanks {
    background: url(./img/fonMob.png);
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .thanks h3 {
    margin-top: 41vw;
    font-size: 7vw;
    color: #31363d;
    text-align: center;
    margin-bottom: 5vw;
  }
  .thanks > p {
    color: #31363d;
    width: 80vw;
    font-size: 4.2vw;
    text-align: center;
    margin-bottom: 7vw;
  }
  .plank {
    box-sizing: border-box;
    background: linear-gradient(
      90.23deg,
      #133661 0.17%,
      rgba(27, 106, 165, 0.73) 99.8%
    );
    border: 2px solid #8aeaff;
    box-shadow: 0px 4px 20px rgba(83, 131, 203, 0.25);
    border-radius: 20px;
    padding: 3.5vw;
    font-size: 4.5vw;
    color: #fff;
    width: 90vw;
  }
  .thanks .showBusiness {
    display: block !important;
    font-size: 5vw;
    width: fit-content;
    padding: 5vw 10vw;
    border-radius: 20vw;
    margin-top: 8vw;
  }
}
