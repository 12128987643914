.showSlider * {
  font-family: Inter !important;
}
.showSlider {
  background: #f2f4f8;
  overflow-y: scroll;
  max-height: 100vh;
  overflow-x: hidden;
}
.showSliderHeader > img {
  width: 6vw;
}
.showLink img,
.addLink img {
  transition: 0.5s;
}
.addLink a:hover img,
.showLink:hover img {
  transform: rotate(360deg);
}
.showSlider::-webkit-scrollbar-thumb {
  background: #0370f3;
}
.showCSlider::-webkit-scrollbar-thumb {
  background: #0370f3;
}
.showSliderHeader {
  display: flex;
  align-items: center;
  padding: 1vw 4vw;
  width: 100vw;

  border-bottom: 1px solid #d9e3ed;
}
.newBlock {
  padding: 0 4vw;
  margin-top: 3vw;
}
.popUp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  backdrop-filter: blur(4px);
}
.popUpBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5vw;
  border-radius: 1vw;
  background: #fff;
}
.popUpBody h3 {
  font-size: 2vw;
  margin-bottom: 1vw;
}
.popUpBody button {
  padding: 1vw;
  width: 20vw;
  border-radius: 8vw;
  font-size: 1vw;
  /* Vector 334 */

  background: linear-gradient(89.22deg, #29ae13 14.97%, #56bf04 99.48%);
  /* зеленая тень кнопки */
  box-shadow: 0px 4px 12px rgba(5, 255, 0, 0.3);
  border-radius: 8vw;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.1vw;
  color: #fff;
}
.popUpBody input {
  margin-bottom: 1vw;
  padding: 1vw;
  width: 20vw;
  font-size: 1vw;
  border-radius: 8vw;
  border: none;
  background: #d5e6f5;
}
.popUpBody input::placeholder {
  color: #6a92b6;
}
.tuda {
  width: 80vw;
}
.lamp {
  display: flex;
  margin-top: 2vw;
}
.lampLeft p {
  color: #338fff;
  font-size: 3vw;
  font-weight: bold;
  margin-bottom: 1vw;
}
.lampLeft h3 {
  background: #ffffff;
  padding: 1.5vw;
  border-radius: 0.5vw;
  display: flex;
  width: fit-content;
  flex-direction: column;
  color: #31363d;
  font-weight: 600;
  font-size: 2vw;
}
.lamp img {
  margin-right: 2vw;
  width: 12vw;
}
header {
  position: relative;
}
.autm {
  position: absolute;
  bottom: 5vw;
  left: 50%;
  translate: -50% 0;
}
@keyframes rotf {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
@keyframes slidef {
  0%,
  100% {
    translate: -50% -50%;
  }
  50% {
    translate: -50% -35%;
  }
}
.k3 {
  animation: rotf linear 10s infinite;
}
.k1 {
  animation: slidef linear 2s infinite;
}
.autm img {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}
.popUpBody {
  background: url(./img/shap.png);
  background-size: 100%;
  background-position-y: top;
  background-repeat: no-repeat;
  background-color: #fff;
}
.popUpBody h3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 1.5vw;
  line-height: 1.5vw;
}
.popUpBody h3 span {
  color: #48d3ff;
}
.popUpBody p {
  color: #fff;
  font-size: 0.9vw;
  text-align: center;
  width: 20vw;
  margin-bottom: 4vw;
}
.lampLeft h3 span {
  color: #0073ff;
  font-weight: bold;
}
.newBlock h2 {
  color: #31363d;
  font-size: 5vw;
}
.newBlock h2 span {
  font-size: 5vw;
  color: #3e95ff;
}
.newBlock h2 {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  margin-bottom: 2vw;
}
.blob p {
  font-size: 1.5vw;
  line-height: 2vw;
}
.land header {
  min-height: 700px;
}
.showLink {
  background: #ffffff;
  box-shadow: 0px 6.86651px 34.3326px rgba(83, 131, 203, 0.25);
  border-radius: 10px;
  padding: 0.5vw;
  display: flex;
  align-items: center;
  font-size: 0.8vw;
  color: black;
  margin-left: 1vw;
  text-decoration: none;
}
.showBusinesss {
  transition: 0.3s;
}
@media (min-width: 640px) {
  .showBusinesss:hover {
    transform: translateY(-5px);
  }
}
.bonus {
  display: flex;
  margin-top: 2vw;
  justify-content: space-between;
} /* Frame 408496116 */
.bonusEm {
  display: flex;
  background: #ffffff;
  border-radius: 1vw;
  box-shadow: 0px 10px 20px rgba(141, 202, 237, 0.25);
  padding: 1vw;
  align-items: center;
  padding-right: 1.2vw;
}
.bonusEm div p {
  color: #3e95ff;
  font-weight: bold;
  margin-bottom: 0.6vw;
  font-size: 1.2vw;
  border-bottom: 1px solid #e7ecf1;
  padding-bottom: 0.5vw;
}
.bonusEm div span {
  font-size: 1vw;
}
.bonusEm img {
  margin-right: 1vw;
}
.showLink img {
  margin-left: 0.5vw;
  width: 2vw;
}
.showSliderHeader > p {
  font-weight: bold;
  margin-left: 10vw;
  font-size: 1vw;
}
.showbutton {
  background: #0370f3;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05vw;
  font-size: 0.8vw;
  padding: 1vw 1.5vw;
  border-radius: 6vw;
  margin-left: 4vw;
  margin-right: 1vw;
}
.kos {
  display: flex;
  align-items: center;
  font-size: 4vw;
  font-weight: 600;
}
.stlft {
  transform: rotate(180deg);
}
.kos svg {
  margin: 0 2vw;
  width: 6vw;
  height: 6vw;
}
.showBusiness {
  background: linear-gradient(89.22deg, #29ae13 14.97%, #56bf04 99.48%);
  box-shadow: 0px 6.3381px 17.2857px rgba(5, 255, 0, 0.3);
  border-radius: 6vw;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05vw;
  font-size: 0.8vw;
  padding: 1vw 1.5vw;
  border-radius: 6vw;

  margin-right: 1vw;
}
.showContacs {
  margin-left: auto;
}
.showContacs a span {
  color: #3e95ff;
}
.showContacs a {
  font-size: 1.5vw;
  color: black;
  text-decoration: none;
  font-weight: bold;
}
.showContacs p {
  text-align: right;
  font-size: 0.8vw;
  color: #626262;
}
@media (min-width: 640px) {
  .clients .dotser div {
    width: 1.5vw !important;
    height: 1.5vw !important;
  }
}
.showSlider h2 {
  font-size: 3.5vw;
  text-transform: uppercase;
  margin-left: 4vw;
  margin-top: 4vw;
  display: flex;
  margin-bottom: 2vw;
  color: #0370f3;
}
.showSlider h2 div {
  margin-right: 1vw;
}
.showSlider h2 span {
  color: #31363d;
}
.sliderBody {
  padding: 0 4vw;
}
.leftSliderBody > p {
  font-size: 1.2vw;
  font-weight: 600;
  color: #31363d;
  padding-top: 1vw;
  border-top: 1px solid #b5c0d6;
}
.leftSliderBody > p span {
  color: #0370f3;
}
.sliderButtons {
  display: flex;
  align-items: center;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.sliderButtons img:hover {
  scale: 1.05;
}
.sliderButtons img {
  width: 4vw;
  cursor: pointer;
}
.sliderButtons p {
  margin: 0 2vw;
  font-size: 1vw;
  color: #31363d;
}
.slShowEm {
  display: flex;
  width: 45vw;
  align-items: flex-start;
  justify-content: space-between;
}
.bigSnt {
  width: 72%;
  cursor: zoom-in;

  object-position: 50% 0;
}
.moduls {
  padding: 2vw 4vw;
  background: url(./img/mobH.png);
  background-size: cover;
  margin-bottom: 2vw;
  position: relative;
}
.moduls .showBusiness {
  position: absolute;
  bottom: 6vw;
  left: 6vw;
  font-size: 1.2vw;
  padding: 1.5vw 2.5vw;
}
.moduls h3 {
  /* Интернет-витрина.рф */

  /* identical to box height, or 110px */
  letter-spacing: -0.04em;
  text-transform: uppercase;

  background: linear-gradient(
    90.63deg,
    rgba(49, 54, 61, 0.79) 13.03%,
    #31363d 100.62%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  font-size: 4vw;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  border-bottom: 1px solid #abcef9;
  padding-bottom: 2vw;
}
.vtf {
  width: 80vw;
}
.moduls h3 span {
  letter-spacing: -0.04em;
  text-transform: uppercase;

  background: linear-gradient(90.44deg, #509fff 0.45%, #0073ff 79.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  font-size: 3vw;
}
.newMod {
  display: flex;
  align-items: center;
  margin-top: 1vw;
}
.newMod div {
  display: flex;
  flex-direction: column;
  font-size: 2vw;
  color: #31363d;
}
.newMod > p {
  font-weight: bold;
  font-size: 6vw;
  margin-right: 1vw;
  color: #3e95ff;
}
.newMod div span {
  color: #3e95ff;
  font-weight: bold;
}
.smallSnt {
  width: 20.5%;
  aspect-ratio: 1/4;
  border-bottom: 5px solid #fff;
  object-fit: cover;
  object-position: 50% 0;
  cursor: zoom-in;
}
.bigSnt:hover,
.smallSnt:hover {
  transform: translateY(-5px);
}
.currentShow p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5vw;
  font-weight: 600;
  color: #31363d;
}
.currentShow img {
  width: 2vw;
}
.sliderBody {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.currentShow {
  cursor: pointer;
  width: 39vw;
  border-bottom: 1px solid rgba(3, 112, 243, 0.3);
}
.currentShow > p {
  padding: 1vw;
  border-radius: 0.5vw;
}
.avc {
  filter: brightness(5);
  transform: rotate(180deg);
}
.rightSliderBody {
}
.below {
  padding: 1.5vw;
  border-radius: 1.5vw;
  background: #fff;
}
.rightSliderBody h4 {
  text-transform: uppercase;
  font-size: 2.5vw;
  color: #31363d;
  margin-top: 0.5vw;
}
.rightSliderBody > div > p {
  font-size: 1.3vw;
  margin-top: 0.5vw;
  color: #31363d;
  margin-bottom: 1vw;
}
.currentShow:last-child {
  border-bottom: none;
}
.snBtn {
  background: linear-gradient(89.22deg, #29ae13 14.97%, #56bf04 99.48%);
  box-shadow: 0px 11px 30px rgba(5, 255, 0, 0.3);
  border-radius: 6vw;
  padding: 1.5vw 2vw;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05vw;
  width: fit-content;
}
.rightSliderBody h3 {
  font-size: 2.1vw;
  font-family: Inter;
  color: #31363d;
  padding-top: 1.5vw;
  margin-top: 2.5vw;
  margin-bottom: 0.5vw;
  border-top: 1px solid #b5c0d6;
}
.uwa {
  color: #96a2bc;
  font-size: 1vw;
  display: block;
  margin-bottom: 1.5vw;
}
.currentShow ul {
  list-style-type: none;
  margin-top: 0.5vw;
  overflow: hidden;
}
.currentShow ul li {
  padding: 0.5vw 1vw;
  font-size: 1vw;
  color: #31363d;
  font-weight: 600;
}
.currentShow ul li:hover {
  transform: translateX(5px);
}
.showCSlider {
  position: fixed;
  z-index: 5555;
  backdrop-filter: blur(15px);
  display: flex;

  justify-content: center;
  width: 100vw;
  padding-top: 3vw;
  overflow-y: scroll;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
}
.showCSlider img {
  max-width: 80vw;
  border-radius: 15px;
  margin-bottom: 3vw;
  overflow: hidden;
}
.showFooter {
  padding: 2vw 4vw;
  border-top: 1px solid #96a2bc;
  margin-top: 5vw;
}
.showFooterBody {
  display: flex;
  align-items: center;
}
.showFooterBody > img {
  width: 10vw;
}
.showFooterBody > p {
  font-weight: bold;
  font-size: 2vw;
  color: #31363d;
  margin-left: 8vw;
  margin-right: 2vw;
}
.footerLinks {
  margin-top: 2vw;
}
.footerLinks a {
  color: #626262;
  margin-right: 2vw;
  font-size: 0.8vw;
  text-decoration: none;
}
.footerLinks span {
  font-size: 0.6vw;
  color: #626262;
}
.addLink p {
  font-size: 1.3vw;
  font-weight: bold;
  color: #3e95ff;
  margin-bottom: 1vw;
}
.addLink {
  margin-right: 1.5vw;
}
.addLink a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #31363d;
  font-weight: 500;
  padding: 0.5vw;
  box-shadow: 0px 6.86651px 34.3326px rgba(83, 131, 203, 0.25);
  border-radius: 0.5vw;
  background: #fff;
  font-size: 0.9vw;
}
.addLink div {
  display: flex;
}
.addLink a img {
  width: 2vw;
  margin-left: 0.5vw;
}
.addLink div a {
  margin-right: 1vw;
}
.addLink a:hover {
}
.addLink a:hover img,
.showLink:hover img {
  transform: rotate(360deg);
}
.bigi {
  width: 100%;
}
.snBtn,
.showBusiness {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.showbutton {
  cursor: pointer;
}
.snBtn .flare,
.showBusiness .flare,
.showBusinesss .flare {
  position: absolute;
  top: 0;
  height: 100%;
  width: 45px;
  transform: skewX(-45deg);
  animation: flareAnimation;
  left: -150%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.4)
  );
  animation: flareAnimation 3s infinite linear;
}
.headerLeft h3 {
  /* Для продажи товаров или услуг */
  font-size: 2vw;
  margin: 1vw 0;
  /* identical to box height, or 44px */
  letter-spacing: -0.04em;

  background: linear-gradient(
    90.63deg,
    rgba(49, 54, 61, 0.79) 2.2%,
    #31363d 100.62%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.tits {
  width: 41vw;
}
.headerBody {
  display: flex;
  margin-top: 3vw;
  justify-content: space-between;
  padding: 0 4vw;
}
.mySwiper4 {
  width: 45vw;
  height: 25vw;
}
.mySwiper4 img {
  width: 100%;
}
.hdRight {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dotser {
  display: flex;
  align-items: center;
}
.dotser div {
  width: 0.6vw;
  height: 0.6vw;
  border-radius: 50%;
  background: rgba(106, 112, 128, 0.3);
  margin: 0.3vw;
}
@keyframes am {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes flareAnimation {
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
}
@media (max-width: 640px) {
  .sliderBody {
    flex-direction: column;
  }
  .below {
    width: 100%;
    padding: 4vw;
  }
  .rightSliderBody h4 {
    text-transform: uppercase;
    font-size: 5.5vw;
    color: #31363d;
    margin-top: 0.5vw;
  }
  .rightSliderBody > div > p {
    font-size: 3.3vw;
    margin-top: 0.5vw;
    color: #31363d;
    margin-bottom: 4vw;
  }
  .currentShow p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 5.5vw;
    font-weight: 600;
    width: 100%;
    color: #31363d;
  }
  .rightSliderBody {
    width: 100%;
  }
  .currentShow {
    width: 100%;
  }
  .currentShow img {
    width: 7vw;
  }
  .currentShow > p {
    padding: 3vw;
  }
  .currentShow ul li {
    padding: 1.5vw 3vw;
    font-size: 4.5vw;
    color: #31363d;
    font-weight: 600;
  }
  .slShowEm {
    flex-direction: column;
  }
  .bigSnt {
    width: 100%;
    margin-bottom: 7vw;
  }
  .slShowEm {
    width: 100%;
  }
  .smallSnt {
    width: 100%;
  }
  .showSlider h2 {
    flex-direction: column;
    text-align: center;
    font-size: 6.5vw;
    margin-top: 12vw;
    margin-bottom: 6vw;
    width: 92vw;
  }
  .leftSliderBody > p {
    font-size: 4.2vw;
    font-weight: 600;
    color: #31363d;
    padding-top: 5vw;
    border-top: 1px solid #b5c0d6;
    text-align: center;
  }
  .sliderButtons img {
    width: 18vw;
    cursor: pointer;
  }
  .sliderButtons {
    width: 100%;
    justify-content: space-between;
    margin-top: 6vw;
    margin-bottom: 6vw;
  }
  .sliderButtons p {
    margin: 0 2vw;
    font-size: 4vw;
    color: #31363d;
  }
  .showSliderHeader > img {
    width: 20vw;
  }
  .showLink img {
    margin-left: 0;
    width: 8vw;
  }
  .showLink {
    background: #ffffff;
    box-shadow: 0px 6.86651px 34.3326px rgba(83, 131, 203, 0.25);
    border-radius: 50%;
    padding: 0.5vw;
    display: flex;
    align-items: center;
    font-size: 0.8vw;
    color: black;
    margin-left: 1vw;
    text-decoration: none;
  }
  .showBusiness {
    display: none;
  }
  .showContacs {
    display: none;
  }
  .showSliderHeader > p {
    font-weight: bold;
    margin-left: 31vw;
    font-size: 2.5vw;
    margin-right: 0vw;
    width: 20vw;
    text-align: center;
  }
  .showSliderHeader {
    display: flex;
    align-items: center;
    padding: 3vw 4vw;
    width: 100vw;
    border-bottom: 1px solid #d9e3ed;
  }
  .below {
    margin-top: 6vw;
  }
  .smallSnt {
    aspect-ratio: 1/2;
  }
  .rightSliderBody h3 {
    font-size: 4.5vw;
    font-family: Inter;
    color: #31363d;
    text-align: center;
    padding-top: 5.5vw;
    margin-top: 7.5vw;
    margin-bottom: 0.5vw;
    border-top: 1px solid #b5c0d6;
  }
  .uwa {
    color: #96a2bc;
    font-size: 3.5vw;
    text-align: center;
    display: block;
    margin-top: 3vw;
    margin-bottom: 4.5vw;
  }
  .snBtn {
    background: linear-gradient(89.22deg, #29ae13 14.97%, #56bf04 99.48%);
    box-shadow: 0px 11px 30px rgba(5, 255, 0, 0.3);
    border-radius: 24vw;
    padding: 5.5vw 2vw;
    cursor: pointer;
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 7vw;
    text-transform: uppercase;
    letter-spacing: 0.05vw;
  }
}
.gol {
  display: flex;
  margin-top: 2.5vw;
}
.hands {
  width: 48vw;
  margin-top: 2vw;
  margin-right: -17vw;
}
.golEm {
  display: flex;
  align-items: center;
  margin-right: 1.5vw;
}
.golEm p {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 1vw;
  color: #31363d;
}
.golEm p span {
  font-weight: 500;
}
.golEm img {
  width: 1.5vw;
  margin-right: 0.8vw;
}
.add {
  display: flex;
  align-items: center;
  margin-top: 3vw;
}
.showBusinesss {
  background: linear-gradient(89.22deg, #29ae13 14.97%, #56bf04 99.48%);
  box-shadow: 0px 6.3381px 17.2857px rgba(5, 255, 0, 0.3);
  border-radius: 6vw;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05vw;
  font-size: 1vw;
  padding: 1.7vw 2vw;
  border-radius: 6vw;
  cursor: pointer;
  margin-right: 1.5vw;
  overflow: hidden;
  position: relative;
}
.pred p {
  color: #fe6c03;
  font-weight: bold;
  font-size: 1vw;
}
.pred span {
  font-size: 0.9vw;
}
.complex h2 {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* онлайн-магазин “На стероидах”! */

  /* or 105px */
  text-align: center;
  letter-spacing: -0.04em;
  text-transform: uppercase;

  background: linear-gradient(
    90.63deg,
    rgba(49, 54, 61, 0.79) 13.03%,
    #31363d 100.62%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 5vw;
}
.complex h2 span {
  /* онлайн-магазин “На стероидах”! */

  background: linear-gradient(90.44deg, #509fff 0.45%, #0073ff 79.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.complex {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(./img/fon2.png);
  background-size: cover;
  background-position-x: center;
  margin-top: 4vw;
  padding: 0 4vw;
  position: relative;
  padding-bottom: 7vw;
}
.complex > p {
  margin-bottom: 2vw;
  margin-top: 1vw;
  color: #31363d;
  font-weight: bold;
  font-size: 2.5vw;
}
.comp {
  margin-top: 8vw;
  width: 85vw;
  margin-left: 2.5vw;
}
.strC {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.3;
}
.cent {
  position: absolute;
  top: 21vw;
  width: 90vw;
  left: 5vw;
}
.bonusEm img {
  width: 5.5vw;
}
.lol {
  padding: 0 4vw;
  display: flex;
  justify-content: space-between;
}
.lol h2 {
  color: rgba(209, 217, 233, 1);
  font-size: 8vw;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.lol h2 span {
  color: #3e95ff;
}
.lol h2 img {
  width: 12vw;
  margin-left: 2vw;
}
.rightLol img {
  width: 43.5vw;
}
.leftLol > p {
  text-transform: uppercase;
  font-size: 3vw;
  margin: 2vw 0;
  margin-bottom: 4vw;
  display: flex;
  flex-direction: column;
  color: #31363d;
  font-weight: bold;
}
.leftLol > p span {
  color: #fe6c03;
}
.blob {
  /* Rectangle 1676 */

  background: linear-gradient(
    90.23deg,
    #133661 0.17%,
    rgba(27, 106, 165, 0.9) 99.8%
  );
  border: 2px solid #8aeaff;
  box-shadow: 0px 10px 20px rgba(0, 89, 139, 0.3);
  border-radius: 1vw;
  width: fit-content;
  font-size: 4vw;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  padding: 1vw 2vw;
}
.flexible {
  margin-top: 4vw;
  display: flex;
  align-items: center;
}
.flexible img {
  width: 11vw;
  margin-right: 1vw;
}
.flexible div {
  display: flex;
  flex-direction: column;
  max-width: 16vw;
}
.flexible p {
  color: #3e95ff;
  font-weight: bold;
  font-size: 2.5vw;
  margin-bottom: 1.5vw;
}
.flexible span b {
  color: #fe6c03;
}
.flexible span {
  font-weight: 600;
  line-height: 2vw;
  font-size: 1.2vw;
}
.helpMe {
  margin-top: 4vw;
  display: flex;
  padding: 0 4vw;
}
.helpMe img {
  width: 40vw;
}
.uls div {
  font-weight: bold;
  font-size: 2.5vw;
  color: #31363d;
  margin-bottom: 1vw;
}
.uls p span {
  color: #4e94d6;
  font-size: 1.5vw;
  margin-right: 1vw;
  font-weight: bold;
}
.uls p {
  font-size: 1.3vw;
  padding: 1vw 0;
  color: #31363d;
  border-bottom: 1px solid rgba(48, 48, 48, 0.15);
}
.uls p:last-child {
  border-bottom: none;
}
.uls {
  margin-top: 5.5vw;
  margin-left: 6vw;
}
.car {
  display: flex;
  padding: 0 4vw;
  margin-top: 4vw;
  justify-content: space-between;
}
.leftCat h3 {
  color: #31363d;
  font-size: 2vw;
  margin-bottom: 1vw;
}
.leftCat h2 {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  margin-bottom: 2vw;
}
.leftCat h2 p {
  background: linear-gradient(
    90.63deg,
    rgba(49, 54, 61, 0.79) 2.2%,
    #31363d 100.62%
  );
  font-size: 3.5vw;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
/* Идеально подойдет */
.leftCat h2 span {
  font-size: 4.5vw;
  color: #3e95ff;
}
.leftCat > p {
  font-size: 1.5vw;
  font-weight: 600;
  color: #31363d;
  margin-bottom: 2vw;
}
.rightCar {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rightCar img {
  width: 60vw;
  margin-top: -4vw;
}
.rightCar .showBusinesss {
  margin-right: -3vw;
}
.urist {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.urbl {
  padding: 0 4vw;
  margin-top: 3vw;
  background: url(./img/ur.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.omg {
  display: flex;
  border: 1px solid #0073ff;
  border-radius: 1vw;
  padding: 2vw;
}
.urist > img {
  width: 53vw;
  margin-right: -4vw;
  margin-left: 2vw;
  display: block;
  margin-top: 8vw;
}
.omg ul li {
  display: flex;
  align-items: center;
  padding: 0.5vw 0;
  border-bottom: 1px solid rgba(48, 48, 48, 0.15);
}
.omg ul li:last-child {
  border-bottom: none;
}
.omg ul {
  margin-right: 2vw;
}
.omg ul li p {
  font-size: 1.3vw;
  margin-right: 1vw;
  font-weight: bold;
  color: #509fff;
}
.omg ul li span {
  font-size: 1vw;
}
.pls {
  background: url(./img/pls.png);
  background-size: 100%;
  padding: 2vw 4vw;
  color: #fff;
  display: flex;
  width: 60vw;
  margin: 0 auto;
  margin-top: -0.5vw;
  flex-direction: column;
  align-items: center;
}
.pls p {
  font-size: 2.3vw;
  margin-top: 0.5vw;
  text-transform: uppercase;
  font-weight: bold;
}
.pls span {
  font-size: 1.1vw;
  text-align: center;
  margin-top: 1vw;
  font-weight: bold;
}
.pls span b {
  color: #8aeaff;
}
.urbl .showBusinesss {
  width: fit-content;
  margin: 0 auto;
  margin-top: 2vw;
}
.clients {
  padding: 0 4vw;
}
.clients .mySwiper2 {
}
.clients img {
  width: 100% !important;
  display: block;
  background: #f2f4f8;
}
.ork {
  margin-top: -6vw;
  margin-left: 14vw;
  width: 50.5vw;
}
.vid {
  margin: 0 auto;
  display: block;
  margin-top: 4vw;
  border-radius: 0.5vw;
}
.clients h3 {
  text-align: center;
  margin: 2vw 0;
  margin-top: 5vw;
  font-size: 4vw;
  color: #3e95ff;
  text-transform: uppercase;
}
.clients .pls {
  margin-top: 3vw;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  border-radius: 2vw;
}
.clients .showBusinesss {
  width: fit-content;
  margin: 0 auto;
  margin-top: 2vw;
}
.com {
  padding: 0 20vw;
  margin-top: 4vw;
}
.comBody img {
  width: 48%;
  margin-bottom: 2vw;
}
.comBody {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
.com h3,
.tarifs h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #31363d;
  text-transform: uppercase;
  font-size: 4vw;
  font-weight: bold;
  margin-bottom: 2vw;
}
.com h3 span,
.tarifs h2 span {
  font-size: 5vw;
  color: #3e95ff;
}
.tarifs > p {
  font-size: 2vw;
  color: #31363d;
}
.trEms {
  display: flex;
  width: 100%;
  margin-top: 3vw;
  justify-content: space-between;
  align-items: flex-start;
}
.trEms img {
  width: 23%;
  cursor: pointer;
  transition: 0.5s;
  display: block;
  border-radius: 1vw;
}
.clients .dotser {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 2vw;
}
.clients .dotser div {
  width: 1.5vw;
  margin: 0.5vw;
  height: 1.5vw;
}
@media (min-width: 640px) {
  .trEms img:hover {
    position: relative;

    margin-top: -5px;
    box-shadow: 0px 20px 20px rgba(141, 202, 237, 0.5);
  }
}
.tarifs {
  padding: 0 4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3vw;
}
.tarifs h2 {
  font-size: 4vw;
}
.botBoost {
  display: flex;
  padding: 0 4vw;
  background: url(./img/lst.png);
  background-size: cover;
  margin-top: 4vw;
  justify-content: space-between;
}
.botLeft h2 {
  color: #3e95ff;
  text-transform: uppercase;
  font-size: 4vw;
  margin-top: 4vw;
}
.botLeft > p {
  font-size: 3vw;
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
  padding-bottom: 1.5vw;
  border-bottom: 1px solid #d9e3ed;
  color: #31363d;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}
.botLeft h3 {
  font-weight: 600;
  font-size: 1.5vw;
  margin-bottom: 0.5vw;
}
.botLeft > span {
  color: #3e95ff;
  font-size: 1.5vw;
  display: block;
  margin-bottom: 1vw;
  font-weight: bold;
}
.proc {
  font-size: 6vw;
  font-weight: bold;
  color: #31363d;
  margin-bottom: 2vw;
}
.proc span {
  font-size: 4vw !important;
}
.botLeft > p span {
  color: #fe6c03;
}
.upSSS {
  display: flex;
  align-items: center;
}
.upSSS p {
  font-size: 1vw;
  color: #969fb1;
  padding: 0.5vw 1vw;
  border-radius: 0.3vw;
  background: #fff;
}
.get {
  width: 92%;
}
.arenda h3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 5vw;
  color: #3e95ff;
  text-transform: uppercase;
}
.arenda h3 span {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #31363d;
  text-transform: uppercase;
  font-size: 4vw;
  font-weight: bold;
  margin-bottom: 2vw;
}
.arenda {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3vw;
}
.arenda h4 {
  font-size: 2.5vw;
  width: 40vw;
  text-align: center;
  color: #31363d;
  margin: 2vw 0;
}
.gett {
  margin-bottom: 2vw;
}
.arenda h4 span {
  color: #0073ff;
}
.botRight img {
  width: 45vw;
}
@media (max-width: 640px) {
  .headerBody {
    flex-direction: column;
    margin-top: 8vw;
  }
  .arenda h3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 11vw;
    color: #3e95ff;
    text-transform: uppercase;
  }
  .arenda h3 span {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #31363d;
    text-transform: uppercase;
    font-size: 6vw;
    font-weight: bold;
    margin-bottom: 5vw;
  }
  .arenda h4 {
    font-size: 6.5vw;
    width: 82vw;
    text-align: center;
    color: #31363d;
    margin: 8vw 0;
  }
  .arenda .showBusinesss {
    width: 92%;
  }
  .mySwiperM4 {
    width: 100vw;
  }
  .mySwiperM4 .swiper-slide {
    padding: 4vw;
  }
  .tits {
    width: 100%;
  }
  .headerLeft h3 {
    font-size: 5vw;
    margin: 3vw 0;
    letter-spacing: -0.04em;
    background: linear-gradient(
      90.63deg,
      rgba(49, 54, 61, 0.79) 2.2%,
      #31363d 100.62%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .bonusEm {
    flex-direction: column;
  }
  .bonusEm img {
    width: 30vw;
    margin-right: 0;
    margin-bottom: 4vw;
  }
  .bonusEm {
    display: flex;
    background: #ffffff;
    border-radius: 5vw;
    box-shadow: 0px 10px 20px rgba(141, 202, 237, 0.25);
    padding: 3vw;
    align-items: center;
    /* padding-right: 1.2vw; */
  }
  .bonus {
    margin: 6vw 0;
  }
  .bonusEm div span {
    font-size: 3.3vw;
    font-weight: 500;
  }
  .bonusEm div p {
    color: #3e95ff;
    font-weight: bold;
    margin-bottom: 0.6vw;
    width: 100%;
    font-size: 4vw;
    border-bottom: 1px solid #e7ecf1;
    padding-bottom: 2.5vw;
  }
  .gol {
    flex-direction: column;
    background: rgba(255, 255, 255, 0.5);
    padding: 4vw;
    border-radius: 4vw;
    margin-top: 1vw;
    margin-bottom: 10vw;
    box-shadow: 0px 10px 20px rgba(0, 89, 139, 0.2);
  }
  .golEm img {
    width: 8.5vw;
    margin-right: 2.8vw;
  }
  .complex {
    margin-top: 12vw;
  }
  .hands {
    width: 164vw !important;
    margin-top: 5vw;
    margin-right: -81vw !important;
  }
  .golEm p {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    font-size: 4vw;
    color: #31363d;
  }
  .golEm {
    margin-bottom: 4vw;
  }
  .golEm p span {
    margin-left: 1.5vw;
  }
  .add {
    flex-direction: column-reverse;
  }
  .pred {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pred p {
    color: #fe6c03;
    width: 60%;
    text-align: center;
    font-weight: bold;
    margin-bottom: 3vw;
    font-size: 5vw;
  }
  .pred span {
    font-size: 5vw;
  }
  .showBusinesss {
    background: linear-gradient(89.22deg, #29ae13 14.97%, #56bf04 99.48%);
    box-shadow: 0px 6.3381px 17.2857px rgba(5, 255, 0, 0.3);
    border-radius: 6vw;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.05vw;
    font-size: 4vw;
    padding: 6.7vw 8vw;
    border-radius: 15vw;
    cursor: pointer;
    margin-right: 0;
    text-align: center;
    width: 100%;
    margin: 6vw 0;
    overflow: hidden;
    position: relative;
  }
  .mySwiper4 {
    width: 92vw;
    height: 50vw;
  }
  .dotser div {
    width: 3.6vw;
    height: 3.6vw;
    border-radius: 50%;
    background: rgba(106, 112, 128, 0.3);
    margin: 1vw;
  }
  .dotser {
    margin-top: 3vw;
  }
  .hands {
    width: 100%;
    margin-top: 5vw;
    margin-right: -8vw;
  }
  .moduls h3 {
    letter-spacing: -0.04em;
    text-transform: uppercase;
    background: linear-gradient(
      90.63deg,
      rgba(49, 54, 61, 0.79) 13.03%,
      #31363d 100.62%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 7vw;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    border-bottom: 1px solid #abcef9;
    padding-bottom: 6vw;
  }
  .moduls h3 span {
    letter-spacing: -0.04em;
    text-transform: uppercase;
    background: linear-gradient(90.44deg, #509fff 0.45%, #0073ff 79.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 6vw;
  }
  .moduls {
    padding: 10vw 4vw;
    background: url(http://localhost:3000/static/media/mobH.2bdf6f4….png);
    background-size: cover;
    margin-bottom: 2vw;
    position: relative;
  }
  .newMod {
    display: flex;
    align-items: center;
    margin-top: 6vw;
    flex-direction: column;
    align-items: flex-start;
  }
  .newMod div {
    display: flex;
    flex-direction: column;
    font-size: 6.5vw;
    color: #31363d;
  }
  .vtf {
    width: 100vw;
    margin-left: -4vw;
    margin-top: 4vw;
  }
  .moduls .showBusiness {
    display: block;
  }
  .newMod > p {
    font-weight: bold;
    font-size: 14vw;
    margin-right: 3vw;
    color: #3e95ff;
  }
  .moduls .showBusiness {
    position: absolute;
    bottom: 20vw;
    left: 9vw;
    font-size: 4.2vw;
    border-radius: 20vw;
    padding: 4.5vw 8.5vw;
  }
  .popUpBody h3 {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 1.5vw;
    line-height: 6.5vw;
  }
  .popUpBody p {
    color: #fff;
    font-size: 3.9vw;
    margin-bottom: 15vw;
    text-align: center;
    width: 69vw;
  }
  .complex h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    background: linear-gradient(
      90.63deg,
      rgba(49, 54, 61, 0.79) 13.03%,
      #31363d 100.62%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 8vw;
  }
  .complex > p {
    margin-bottom: 8vw;
    margin-top: 2vw;
    padding: 0 18vw;
    color: #31363d;
    text-align: center;
    font-weight: bold;
    font-size: 4.5vw;
  }
  .mobAd img {
    width: 100%;
    margin-bottom: 4vw;
  }
  .t6 {
    margin-top: 6vw;
  }
  .complex {
    background-image: url(./img/mf1.png);
    padding-bottom: 10vw;
  }
  .newBlock h2 span {
    font-size: 9vw;
    color: #3e95ff;
  }
  .newBlock h2 {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    align-items: center;
    margin-bottom: 5vw;
  }
  .newBlock h2 {
    color: #31363d;
    font-size: 6vw;
  }
  .lamp {
    flex-direction: column;
    align-items: center;
  }
  .lamp img {
    margin-right: 0;
    width: 28vw;
    margin-top: 6vw;
    margin-bottom: 6vw;
  }
  .lampLeft p {
    color: #338fff;
    font-size: 6vw;
    text-align: center;
    font-weight: bold;
    margin-bottom: 8vw;
  }
  .arenda {
    margin-top: 8vw;
  }
  .lampLeft h3 {
    background: #ffffff;
    padding: 3.5vw;
    border-radius: 3.5vw;
    display: flex;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    flex-direction: column;
    color: #31363d;
    font-weight: 600;
    font-size: 4.5vw;
  }
  .autm {
    display: none;
  }
  .lol {
    flex-direction: column;
    margin-top: 2vw;
  }
  .lol h2 {
    color: rgba(209, 217, 233, 1);
    font-size: 18vw;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
  .lol h2 img {
    width: 26vw;
    margin-left: 4vw;
  }
  .leftLol > p {
    text-transform: uppercase;
    font-size: 9vw;
    width: 73%;
    margin: 2vw 0;
    margin-bottom: 4vw;
    display: flex;
    flex-direction: column;
    color: #31363d;
    font-weight: bold;
  }
  .blob {
    background: linear-gradient(
      90.23deg,
      #133661 0.17%,
      rgba(27, 106, 165, 0.9) 99.8%
    );
    border: 2px solid #8aeaff;
    box-shadow: 0px 10px 20px rgba(0, 89, 139, 0.3);
    border-radius: 2vw;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 11vw;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    padding: 3vw 6vw;
  }
  .flexible img {
    width: 30vw;
    margin-right: 5vw;
  }
  .flexible p {
    color: #3e95ff;
    font-weight: bold;
    font-size: 7.5vw;
    margin-bottom: 4.5vw;
  }
  .flexible div {
    display: flex;
    flex-direction: column;
    max-width: unset;
  }
  .flexible span {
    font-weight: 600;
    line-height: normal;
    font-size: 4.2vw;
  }
  .flexible {
    margin: 8vw 0;
  }
  .rightLol img {
    width: 100%;
  }
  .helpMe {
    margin-top: 9vw;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 4vw;
  }
  .uls div {
    font-weight: bold;
    font-size: 7.5vw;
    color: #31363d;
    margin-bottom: 1vw;
  }
  .uls {
    margin-top: -0.5vw;
    margin-left: 0;
  }
  .uls p {
    font-size: 5vw;
    padding: 3vw 0;
    display: flex;
    color: #31363d;
    border-bottom: 1px solid rgba(48, 48, 48, 0.15);
  }
  .uls p span {
    font-size: 7vw;
    margin-right: 4vw;
  }
  .helpMe img {
    width: 100%;
    margin-top: 6vw;
    margin-bottom: 6vw;
  }
  .car {
    flex-direction: column;
  }
  .leftCat h3 {
    color: #31363d;
    font-size: 5vw;
    margin-bottom: 5vw;
  }
  .leftCat h2 span {
    font-size: 13.5vw;
    color: #3e95ff;
  }
  .leftCat h2 p {
    background: linear-gradient(
      90.63deg,
      rgba(49, 54, 61, 0.79) 2.2%,
      #31363d 100.62%
    );
    font-size: 10.5vw;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .leftCat > p {
    font-size: 4.5vw;
    font-weight: 600;
    margin-top: 6vw;
    color: #31363d;
    margin-bottom: 6vw;
  }
  .blob p {
    font-size: 4.5vw;
    line-height: 7vw;
  }
  .rightCar img {
    width: 100%;
    margin-top: 6vw;
    margin-bottom: 3vw;
  }
  .rightCar .showBusinesss {
    margin-right: 0;
  }
  .urist {
    flex-direction: column;
  }
  .urist > img {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    display: block;
    margin-top: 8vw;
  }
  .mySwiperM1 {
    width: 92vw;
  }
  .com h3 span {
    font-size: 13vw;
    color: #3e95ff;
  }
  .com h3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #31363d;
    text-transform: uppercase;
    font-size: 10vw;
    font-weight: bold;
    margin-bottom: 7vw;
  }
  .omg {
    flex-direction: column;
    align-items: center;
  }
  .omg .dotser div {
    width: 5vw;
    height: 5vw;
  }
  .grCas {
    width: 100%;
    text-align: center;
    padding: 5vw 0;
    font-weight: 600;
    color: #31363d;
    margin-top: 6vw;
    font-size: 4vw;
    border-radius: 20vw;
    border: 2px solid #53be06;
  }
  .pls p {
    font-size: 6.3vw;
    margin-top: 24.5vw;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }
  .pls span {
    font-size: 4.1vw;
    text-align: center;
    margin-top: 4vw;
    line-height: 6vw;
    font-weight: 500;
    padding-bottom: 14vw;
  }
  .clients .pls {
    margin-top: 12vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    border-radius: 7vw;
  }
  .pls {
    background-image: url(./img/mobs.png);
    background-size: cover;
    width: 100%;
    margin-bottom: 10vw;
    margin-top: 6vw;
  }
  .mySwiperM1 img {
    width: 100%;
    margin-bottom: 5vw;
  }
  .omg {
    width: 92vw;
    border-radius: 4vw;
    margin-top: 10vw;
    padding: 8vw 5vw;
  }
  .com {
    padding: 0 4vw;
  }
  .omg ul li p {
    font-size: 6vw;
    margin-right: 1vw;
    width: 10vw;
    text-align: center;
    font-weight: bold;
    color: #509fff;
  }
  .omg ul li span {
    font-size: 4.5vw;
  }
  .omg ul li {
    display: flex;
    align-items: center;
    padding: 2vw 0;
  }
  .botBoost {
    flex-direction: column;
  }
  .botLeft h2 {
    color: #3e95ff;
    text-transform: uppercase;
    font-size: 8.5vw;
    margin-top: 10vw;
  }
  .botLeft > p {
    font-size: 6vw;
    margin-top: 1.5vw;
    margin-bottom: 6.5vw;
    padding-bottom: 6.5vw;
    border-bottom: 1px solid #d9e3ed;
    color: #31363d;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    flex-direction: column;
  }
  .botLeft h3 {
    font-weight: 600;
    font-size: 4vw;
    margin-bottom: 3.5vw;
  }
  .botLeft > span {
    color: #3e95ff;
    font-size: 5.5vw;
    display: block;
    margin-bottom: 1vw;
    font-weight: bold;
  }
  .proc {
    font-size: 20vw;
    font-weight: bold;
    color: #31363d;
    margin-bottom: 2vw;
  }
  .proc span {
    font-size: 13vw !important;
  }
  .upSSS {
    flex-direction: column;
  }
  .clients .showBusinesss {
    width: 100%;
  }
  .upSSS p {
    font-size: 3vw;
    color: #969fb1;
    padding: 0.5vw 6vw;
    border-radius: 0.3vw;
    background: none;
    margin-top: -3vw;
  }
  .botRight img {
    width: 100%;
    margin: 5vw 0;
  }
  .showFooterBody {
    flex-direction: column;
  }
  .showFooter {
    padding: 8vw 4vw;
    border-top: 1px solid #96a2bc;
    margin-top: 5vw;
  }
  .showFooterBody > p {
    font-weight: bold;
    font-size: 6vw;
    color: #31363d;
    margin-left: 0;
    margin-top: 6vw;
    margin-right: 0;
  }
  .addLink p {
    font-size: 5.3vw;
    font-weight: bold;
    color: #3e95ff;
    margin-bottom: 3vw;
  }
  .addLink a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #31363d;
    font-weight: 500;
    padding: 2.5vw;
    box-shadow: 0px 6.86651px 34.3326px rgba(83, 131, 203, 0.25);
    border-radius: 1.5vw;
    background: #fff;
    font-size: 4.9vw;
  }
  .addLink a img {
    width: 6vw;
    margin-left: 2.5vw;
  }
  .addLink div a {
    margin-right: 3vw;
  }
  .footerLinks {
    margin-top: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footerLinks a {
    color: #626262;
    margin-right: 0;
    font-size: 2.8vw;
    margin-top: 5vw;
    text-decoration: none;
  }
  .footerLinks span {
    font-size: 2.6vw;
    margin-top: 6vw;
    color: #626262;
  }
  .addLink {
    margin-right: 0;
    margin-top: 5vw;
  }
  .showFooterBody > img {
    width: 36vw;
  }
  .addLink {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tarifs h2 {
    font-size: 10vw;
  }
  .tarifs > p {
    font-size: 5vw;
    text-align: center;
    color: #31363d;
  }

  .tarifs {
    padding: 0 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 11vw;
  }
  .com h3 span,
  .tarifs h2 span {
    font-size: 10vw;
    color: #3e95ff;
  }
  .trEms > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .trEms .dotser div {
    width: 5vw;
    height: 5vw;
    margin: 1.5vw;
  }
  .clients .dotser div,
  .com .dotser div {
    width: 5vw;
    margin: 1.5vw;
    height: 5vw;
  }
  .clients h3 {
    text-align: center;
    margin: 5vw 0;
    margin-top: 11vw;
    font-size: 10vw;
    color: #3e95ff;
    text-transform: uppercase;
  }
  .com .dotser {
    display: flex;
    justify-content: center;
    margin-top: -2vw;
  }
  .trEms .dotser {
    margin-bottom: 3vw;
  }
  .mySwiperM2 {
    width: 92vw;
  }
  .mySwiperM2 img {
    width: 100% !important;
  }
  .popUpBody {
    width: 92vw;
    padding: 4vw;
    border-radius: 3vw;
  }
  .popUpBody h3 {
    font-size: 6vw;
    margin-bottom: 4vw;
  }
  .popUpBody button {
    padding: 4vw;
    width: 100%;
    font-size: 4vw;
  }
  .popUpBody input {
    padding: 4vw;
    font-size: 4vw;
    border-radius: 18vw;
    width: 100%;
    margin-bottom: 4vw;
  }
  .bonus {
    flex-direction: row-reverse;
  }
  .bonusEm img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 4vw;
  }
  .bonus .bonusEm:first-child {
    margin-left: 3vw;
  }
  .land header {
    background: url(./img/mobH.png);
    background-size: cover;
  }
  .tuda {
    width: 100%;
  }
  .ork {
    width: 92vw;
    margin-top: 8vw;
    margin-left: 0;
  }
  .vid {
    width: 92vw;
    margin-top: 8vw;
    border-radius: 3vw;
  }
}
