@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: Inter;
  box-sizing: border-box;
}
.App {
  background: #f2f4f8;
  overflow-x: hidden;
}
