.showSlider * {
  transition: 0.3s;
}
.showSlider {
  background: #f2f4f8;
  overflow-y: scroll;
  max-height: 100vh;
  overflow-x: hidden;
}
.showSliderHeader > img {
  width: 6vw;
}
.showSlider::-webkit-scrollbar-thumb {
  background: #0370f3;
}
.showCSlider::-webkit-scrollbar-thumb {
  background: #0370f3;
}
.showSliderHeader {
  display: flex;
  align-items: center;
  padding: 1vw 4vw;
  width: 100vw;
  border-bottom: 1px solid #d9e3ed;
}
.showLink {
  background: #ffffff;
  box-shadow: 0px 6.86651px 34.3326px rgba(83, 131, 203, 0.25);
  border-radius: 10px;
  padding: 0.5vw;
  display: flex;
  align-items: center;
  font-size: 0.8vw;
  color: black;
  margin-left: 1vw;
  text-decoration: none;
}
.showLink img {
  margin-left: 0.5vw;
  width: 2vw;
}
.showSliderHeader > p {
  font-weight: bold;
  margin-left: 10vw;
  font-size: 1vw;
}
.showbutton {
  background: #0370f3;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05vw;
  font-size: 0.8vw;
  padding: 1vw 1.5vw;
  border-radius: 6vw;
  margin-left: 4vw;
  margin-right: 1vw;
}
.showBusiness {
  background: linear-gradient(89.22deg, #29ae13 14.97%, #56bf04 99.48%);
  box-shadow: 0px 6.3381px 17.2857px rgba(5, 255, 0, 0.3);
  border-radius: 6vw;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05vw;
  font-size: 0.8vw;
  padding: 1vw 1.5vw;
  border-radius: 6vw;

  margin-right: 1vw;
}
.showContacs {
  margin-left: auto;
}
.showContacs a span {
  color: #3e95ff;
}
.showContacs a {
  font-size: 1.5vw;
  color: black;
  text-decoration: none;
  font-weight: bold;
}
.showContacs p {
  text-align: right;
  font-size: 0.8vw;
  color: #626262;
}
.showSlider h2 {
  font-size: 3.2vw;
  text-transform: uppercase;
  margin-left: 4vw;
  margin-top: 4vw;
  display: flex;
  margin-bottom: 2vw;
  color: #0370f3;
}
.showSlider h2 div {
  margin-right: 1vw;
}
.showSlider h2 span {
  color: #31363d;
}
.sliderBody {
  padding: 0 4vw;
}
.leftSliderBody > p {
  font-size: 1.2vw;
  font-weight: 600;
  color: #31363d;
  padding-top: 1vw;
  border-top: 1px solid #b5c0d6;
}
.leftSliderBody > p span {
  color: #0370f3;
}
.sliderButtons {
  display: flex;
  align-items: center;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.sliderButtons img:hover {
  scale: 1.05;
}
.sliderButtons img {
  width: 4vw;
  cursor: pointer;
}
.sliderButtons p {
  margin: 0 2vw;
  font-size: 1vw;
  color: #31363d;
}
.slShowEm {
  display: flex;
  width: 45vw;
  align-items: flex-start;
  justify-content: space-between;
}
.bigSnt {
  width: 32vw;
  cursor: zoom-in;

  object-position: 50% 0;
}
.smallSnt {
  width: 12vw;
  aspect-ratio: 1/4;
  border-bottom: 5px solid #fff;
  object-fit: cover;
  object-position: 50% 0;
  cursor: zoom-in;
}
.bigSnt:hover,
.smallSnt:hover {
  transform: translateY(-5px);
}
.currentShow p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5vw;
  font-weight: 600;
  color: #31363d;
}
.currentShow img {
  width: 2vw;
}
.sliderBody {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.currentShow {
  cursor: pointer;
  width: 39vw;
  border-bottom: 1px solid rgba(3, 112, 243, 0.3);
}
.currentShow > p {
  padding: 1vw;
  border-radius: 0.5vw;
}
.avc {
  filter: brightness(5);
  transform: rotate(180deg);
}
.rightSliderBody {
}
.below {
  padding: 1.5vw;
  border-radius: 1.5vw;
  background: #fff;
}
.rightSliderBody h4 {
  text-transform: uppercase;
  font-size: 2.5vw;
  color: #31363d;
  margin-top: 0.5vw;
}
.rightSliderBody > div > p {
  font-size: 1.3vw;
  margin-top: 0.5vw;
  color: #31363d;
  margin-bottom: 1vw;
}
.currentShow:last-child {
  border-bottom: none;
}
.snBtn {
  background: linear-gradient(89.22deg, #29ae13 14.97%, #56bf04 99.48%);
  box-shadow: 0px 11px 30px rgba(5, 255, 0, 0.3);
  border-radius: 6vw;
  padding: 1.5vw 2vw;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05vw;
  width: fit-content;
}
.keks {
  animation: keks1 3s ease infinite;
}
@keyframes keks1 {
  0%,
  100% {
    margin-top: 0;
  }
  50% {
    margin-top: -3vw;
  }
}
.rightSliderBody h3 {
  font-size: 2.1vw;
  font-family: Inter;
  color: #31363d;
  padding-top: 1.5vw;
  margin-top: 2.5vw;
  margin-bottom: 0.5vw;
  border-top: 1px solid #b5c0d6;
}
.uwa {
  color: #96a2bc;
  font-size: 1vw;
  display: block;
  margin-bottom: 1.5vw;
}
.currentShow ul {
  list-style-type: none;
  margin-top: 0.5vw;
  overflow: hidden;
}
.currentShow ul li {
  padding: 0.5vw 1vw;
  font-size: 1vw;
  color: #31363d;
  font-weight: 600;
}
.currentShow ul li:hover {
  transform: translateX(5px);
}
.showcsliderBody {
  width: fit-content;
  margin: 0 auto;
}
.closeShow {
  display: flex;
  justify-content: flex-end;
  padding: 0.5vw;
  margin-bottom: 4vw;
}
.showCSlider {
  position: fixed;
  z-index: 5555;
  backdrop-filter: blur(15px);
  display: flex;

  justify-content: center;
  width: 100vw;
  padding-top: 0vw;
  overflow-y: scroll;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
}
.showCSlider img {
  max-width: 80vw;
  border-radius: 15px;
  margin-bottom: 3vw;
  overflow: hidden;
}
.showFooter {
  padding: 2vw 4vw;
  border-top: 1px solid #96a2bc;
  margin-top: 5vw;
}
.showFooterBody {
  display: flex;
  align-items: center;
}
.showFooterBody > img {
  width: 10vw;
}
.showFooterBody > p {
  font-weight: bold;
  font-size: 2vw;
  color: #31363d;
  margin-left: 8vw;
  margin-right: 2vw;
}
.footerLinks {
  margin-top: 2vw;
}
.footerLinks a {
  color: #626262;
  margin-right: 2vw;
  font-size: 0.8vw;
  text-decoration: none;
}
.ogr {
  display: flex;
}
.linkVit {
  margin-left: auto;
  display: block;
  display: flex;
  align-items: center;
  font-size: 1vw;
  font-weight: 500;
}
.linkVit:hover {
  color: #0370f3;
}
.linkVit svg {
  width: 1.5vw;
  height: 1.5vw;
  margin-left: 0.5vw;
}
.footerLinks span {
  font-size: 0.6vw;
  color: #626262;
}
.addLink p {
  font-size: 1.3vw;
  font-weight: bold;
  color: #3e95ff;
  margin-bottom: 1vw;
}
.addLink {
  margin-right: 1.5vw;
}
.addLink a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #31363d;
  font-weight: 500;
  padding: 0.5vw;
  box-shadow: 0px 6.86651px 34.3326px rgba(83, 131, 203, 0.25);
  border-radius: 0.5vw;
  background: #fff;
  font-size: 0.9vw;
}
.addLink div {
  display: flex;
}
.addLink a img {
  width: 2vw;
  margin-left: 0.5vw;
}
.addLink div a {
  margin-right: 1vw;
}
.addLink a:hover {
}
.addLink a:hover img,
.showLink:hover img {
  transform: rotate(360deg);
}
.bigi {
  width: 100%;
}
.snBtn,
.showBusiness {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.showbutton {
  cursor: pointer;
}
.snBtn .flare,
.showBusiness .flare {
  position: absolute;
  top: 0;
  height: 100%;
  width: 45px;
  transform: skewX(-45deg);
  animation: flareAnimation;
  left: -150%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.4)
  );
  animation: flareAnimation 3s infinite linear;
}
@keyframes am {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes flareAnimation {
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
}
.closeShow svg {
  width: 2.5vw;
  height: 2.5vw;
}
.closeShow {
  position: sticky;
  top: 0;
  right: 0;
  background: black;
}
.showCSlider {
  flex-direction: column;
  justify-content: flex-start;
}
.slShowEm div {
  position: relative;
}
.keks {
  position: absolute !important;
  z-index: 20;
  top: 50%;
  left: 50%;
  pointer-events: none;
  width: 15vw;
  transform: translate(-50%, -50%) !important;
}
@media (max-width: 640px) {
  .showCSlider {
    padding-top: 0vw;
  }
  .showCSlider {
    flex-direction: column;
    justify-content: flex-start;
  }
  .closeShow {
    position: sticky;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 1vw;
    background: black;
  }
  .showcsliderBody {
    display: flex;
    margin-top: 5vw;
    flex-direction: column;
    align-items: center;
  }
  .showCSlider img {
    width: 92vw;
    max-width: 92vw;
  }
  .sliderBody {
    flex-direction: column-reverse;
  }
  .below {
    width: 100%;
    padding: 4vw;
  }
  .closeShow svg {
    width: 12vw;
    height: 12vw;
  }
  .rightSliderBody h4 {
    text-transform: uppercase;
    font-size: 5.5vw;
    color: #31363d;
    margin-top: 0.5vw;
  }
  .rightSliderBody > div > p {
    font-size: 3.3vw;
    margin-top: 0.5vw;
    color: #31363d;
    margin-bottom: 4vw;
  }
  .slShow h3 {
    font-size: 4.5vw;
    text-align: center;
    margin-top: 8vw;
  }
  .currentShow p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 5.5vw;
    font-weight: 600;
    width: 100%;
    color: #31363d;
  }
  .rightSliderBody {
    width: 100%;
  }
  .currentShow {
    width: 100%;
  }
  .currentShow img {
    width: 7vw;
  }
  .currentShow > p {
    padding: 3vw;
  }
  .currentShow ul li {
    padding: 2.5vw 3vw;
    font-size: 4.5vw;
    color: #31363d;
    font-weight: 500;
    border-bottom: 1px solid #a5cdfd;
  }
  .currentShow ul li:last-child {
    border-bottom: none;
  }
  .slShowEm {
    flex-direction: column;
  }
  .bigSnt {
    width: 100%;
    margin-bottom: 7vw;
  }
  .slShowEm {
    width: 100%;
  }
  .smallSnt {
    width: 100%;
  }
  .showSlider h2 {
    flex-direction: column;
    text-align: center;
    font-size: 6.5vw;
    margin-top: 12vw;
    margin-bottom: 6vw;
    width: 92vw;
  }
  .leftSliderBody > p {
    font-size: 4.2vw;
    font-weight: 600;
    color: #31363d;
    padding-top: 5vw;
    border-top: 1px solid #b5c0d6;
    text-align: center;
  }
  .sliderButtons img {
    width: 18vw;
    cursor: pointer;
  }
  .sliderButtons {
    width: 100%;
    justify-content: space-between;
    margin-top: 6vw;
    margin-bottom: 6vw;
  }
  .sliderButtons p {
    margin: 0 2vw;
    font-size: 4vw;
    color: #31363d;
  }
  .showSliderHeader > img {
    width: 20vw;
  }
  .showLink img {
    margin-left: 0;
    width: 8vw;
  }
  .showLink {
    background: #ffffff;
    box-shadow: 0px 6.86651px 34.3326px rgba(83, 131, 203, 0.25);
    border-radius: 50%;
    padding: 0.5vw;
    display: flex;
    align-items: center;
    font-size: 0.8vw;
    color: black;
    margin-left: 1vw;
    text-decoration: none;
  }
  .showBusiness {
    display: none;
  }
  .showbutton {
    background: #0370f3;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.05vw;
    font-size: 2.5vw;
    padding: 3vw 2.5vw;
    border-radius: 6vw;
    margin-left: 4vw;
    margin-right: 0;
  }
  .showContacs {
    display: none;
  }
  .showSliderHeader > p {
    font-weight: bold;
    margin-left: 0;
    font-size: 2.5vw;
    margin-right: 0vw;
    width: 20vw;
    text-align: center;
  }
  .showSliderHeader {
    display: flex;
    align-items: center;
    padding: 3vw 4vw;
    width: 100vw;
    border-bottom: 1px solid #d9e3ed;
  }
  .below {
    margin-top: 6vw;
  }
  .smallSnt {
    aspect-ratio: 1/2;
  }
  .rightSliderBody h3 {
    font-size: 4.5vw;
    font-family: Inter;
    color: #31363d;
    text-align: center;
    padding-top: 5.5vw;
    margin-top: 7.5vw;
    margin-bottom: 0.5vw;
    border-top: 1px solid #b5c0d6;
  }
  .slShowEm img {
    position: relative;
  }

  .uwa {
    color: #96a2bc;
    font-size: 3.5vw;
    text-align: center;
    display: block;
    margin-top: 3vw;
    margin-bottom: 4.5vw;
  }
  .snBtn {
    background: linear-gradient(89.22deg, #29ae13 14.97%, #56bf04 99.48%);
    box-shadow: 0px 11px 30px rgba(5, 255, 0, 0.3);
    border-radius: 24vw;
    padding: 5.5vw 2vw;
    cursor: pointer;
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 7vw;
    text-transform: uppercase;
    letter-spacing: 0.05vw;
  }
  .showFooterBody {
    flex-direction: column;
  }
  .showFooter {
    padding: 8vw 4vw;
    border-top: 1px solid #96a2bc;
    margin-top: 10vw;
  }
  .showFooterBody > p {
    font-weight: bold;
    font-size: 6vw;
    color: #31363d;
    margin-left: 0;
    margin-top: 6vw;
    margin-right: 0;
  }
  .addLink p {
    font-size: 5.3vw;
    font-weight: bold;
    color: #3e95ff;
    margin-bottom: 3vw;
  }
  .addLink a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #31363d;
    font-weight: 500;
    padding: 2.5vw;
    box-shadow: 0px 6.86651px 34.3326px rgba(83, 131, 203, 0.25);
    border-radius: 1.5vw;
    background: #fff;
    font-size: 4.9vw;
  }
  .addLink a img {
    width: 6vw;
    margin-left: 2.5vw;
  }
  .addLink div a {
    margin-right: 3vw;
  }
  .linkVit {
    font-size: 4vw;
  }
  .linkVit svg {
    width: 6vw;
    height: 6vw;
    margin-left: 2vw;
  }
  .linkVit p {
  }
  .footerLinks {
    margin-top: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footerLinks a {
    color: #626262;
    margin-right: 0;
    font-size: 2.8vw;
    margin-top: 5vw;
    text-decoration: none;
  }
  .footerLinks span {
    font-size: 2.6vw;
    margin-top: 6vw;
    color: #626262;
  }
  .addLink {
    margin-right: 0;
    margin-top: 5vw;
  }
  .showFooterBody > img {
    width: 36vw;
  }
  .addLink {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
